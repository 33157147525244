foreignObject {
  overflow: visible;
}

@mixin node-stats {
  font-size: 12px;
  color: var(--gray_9);
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }

  .anticon {
    margin-right: 5px;
  }
}

.NodeComponent {
  cursor: pointer;
}

.NodeComponent {
  &.lighting-off {
    // filter: none !important;
  }

  &.Square {
    .node-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: space-between;
      justify-content: space-between;
      border: 1px solid var(--white);
      border-radius: 4px;
      text-align: right;
      padding-top: 5px;
      background: var(--white);

      .reached {
        display: flex !important;
        width: 100% !important;
        justify-content: center !important;
        align-items: center !important;
      }

      .node-stats {
        @include node-stats;

        &.disabled {
          opacity: 0.2;
          pointer-events: none;
        }
      }

      .node-info-icon {
        margin-left: auto;
      }

      .node-header {
        height: 72px;
      }

      .node-footer {
        background-color: #fafafa;
        padding: 12px !important;
      }

      .status {
        @include node-stats;
        display: flex;
        justify-content: center;
        &-value {
          text-decoration: underline;
        }
      }

      .node-header,
      .node-footer-stats {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .node-body {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          line-height: 16px;
          width: 70%;
        }

        .icon {
          padding: 8px;
          border-radius: 4px;
          svg {
            width: 20px;
            height: 20px;
          }
        }

        .name {
          line-height: 16px;
        }
      }
    }
  }
}

.NodeComponent.Round {
  .node-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .node-stats {
      @include node-stats;
      text-align: center;
      width: 100px;
      text-shadow: 0px 0px 5px rgba(var(--white), 1);
    }

    .icon-wrapper {
      padding: 8px;
      border-radius: 50%;
      background: rgba(var(--gray_3), 0.9);
      border: 2px solid var(--white);

      .icon {
        // width: 21px;
        // height: 21px;
      }
    }

    .anticon {
      margin-left: 5px;
    }

    .text {
      text-align: center;
      font-weight: bold;
      display: flex;
      justify-content: center;
      font-size: 14px;
      text-shadow: 0px 0px 5px rgba(var(--white), 1);
    }
  }
}

.node-prompt {
  .ant-modal-confirm-body {
    .ant-modal-confirm-title {
      font-size: 18px;
      color: var(--brand_primary);
      font-weight: 400;

      .node-name {
        font-weight: 700;
      }
    }

    .ant-modal-confirm-content {
      margin: 0 !important;
      color: var(--red);
      font-weight: 400;
    }
  }
}
