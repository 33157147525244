.common-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@mixin CJ-circle-button {
  background: rgba(var(--white), 0.7);
  border: 1px solid var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-size: 12px;
  transform: translate(0, 0);
  transition: 0.3s;
  color: var(--gray_9);
  overflow: visible;
  box-shadow: 0 0 10px rgba(var(--gray_9), 0.2);
  z-index: 100;
  backdrop-filter: blur(10px);
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(var(--white), 0.5);
  }
}

@mixin status-color-scheme {
  &.green {
    background: var(--positive);
  }

  &.light-gray {
    background: var(--gray_7);
  }

  &.yellow {
    background: var(--yellow_5);
  }

  &.pink {
    background: var(--magenta_5);
  }
  &.cyan {
    background: var(--cyan_5);
  }
  &.brown {
    background: var(--gold_9);
  }
  &.red {
    background: var(--red_6);
  }

  &.blue {
    background: var(--blue_6);
  }

  &.gray {
    background: var(--gray_9);
  }
}
